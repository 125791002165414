
import { BRow, BCol, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  name: "AvSkeletonChartBar",
  components: {
    BRow,
    BCol,
    BSkeleton,
    BSkeletonWrapper,
  },
})
export default class AvSkeletonChartBar extends Vue {
  @Prop({ default: "350px" }) height!: string;
  @Prop({ default: 3 }) columns!: number;

  generateColumnHeight(index: number): string {
    return Math.round(Math.random() * 100) + 1 + "%";
  }
}
