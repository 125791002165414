
import { AvButton, AvExportButton } from "@/components";
import { AvSkeletonTable } from "@/components/av-skeleton";
import ENUMS from "@/enums";
// Services
import { useRegion } from "@/services";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// Interfaces
import {
  IRegion,
  IRegionApi,
  IResponseListRegion,
} from "@core/services/interfaces/covarege-area/region/IRegionService";
import { Ref, ref } from "@vue/composition-api";
import { AxiosResponse } from "axios";
import {
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BvTableFieldArray,
  VBModal,
} from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import RegionHandler from "../regions-handler/RegionHandler.vue";

@Component({
  name: "RegionsList",
  components: {
    AvButton,
    AvExportButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    RegionHandler,
    AvSkeletonTable,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
})
export default class RegionList extends Vue {
  // Data
  $ENUMS = ENUMS;
  isRegionSidebarActive: Ref<boolean> = ref(false);
  loading = false;
  searchQuery = "";
  perPage: number = 10;
  perPageOptions: Array<number> = [10, 25, 50, 100];
  isSortDirDesc = false;
  currentPage: number = 1;
  display: number = 0;
  total: number = 0;
  sortBy = "nome";
  $refs = {
    refListTable: {} as any,
  };

  blankRegion: IRegion = {
    nome: "",
    codigo: null,
    status: null,
    autor: "",
  };

  statusOptions: Array<{ label: string; value: boolean }> = [
    { label: "Ativo", value: true },
    { label: "Inativo", value: false },
  ];

  tableColumns: BvTableFieldArray = [
    { key: "nome", label: "Nome", sortable: true },
    { key: "codigo", label: "Código", sortable: true },
    { key: "status", label: "Status", sortable: true },
    { key: "autor", label: "Autor", sortable: true },
    { key: "actions", label: "Ações" },
  ];

  region: Ref<IRegion> = ref(JSON.parse(JSON.stringify(this.blankRegion)));
  regions: IRegion[] = [];
  filterOn = [];

  // Computed
  get dataMeta() {
    const localItemsCount = this.regions ? this.regions.length : 0;

    return {
      from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
      to:
        this.perPage * this.currentPage < this.display
          ? this.perPage * this.currentPage
          : this.display,
      of: this.display,
      total: this.total,
    };
  }

  // Lifecycle
  created() {
    this.refreshData();
  }

  // Methods
  onFiltered(filteredItems: Array<any>) {
    this.display = filteredItems.length;
    this.currentPage = 1;
  }

  clearRegionData = () => {
    this.region.value = JSON.parse(JSON.stringify(this.blankRegion));
  };

  /**
   * Essa função é acionada quando queremos abrir o modal de editar região
   * @param {Integer} regionId
   */
  handlerSidebarRegion(regionId: number | undefined) {
    this.isRegionSidebarActive.value = true;
    this.region.value.id = regionId;
  }

  updateIsRegionSidebarActive = (val: boolean): void => {
    this.isRegionSidebarActive.value = val;
  };

  fetchUpdateRegion = (val: boolean): void => {
    this.isRegionSidebarActive.value = val;
  };

  refreshData() {
    this.loading = true;

    useRegion
      .requestList({ draw: 1, length: 100000 })
      .then((response: AxiosResponse<IResponseListRegion>) => {
        const data: IRegion[] = response.data.Data.data.map(
          (region: IRegionApi) => ({
            id: region.Id,
            nome: region.Nome,
            codigo: region.Codigo,
            status: region.Status,
            autor: region.Autor,
          })
        );

        this.regions = data;
        this.display = this.regions.length;
        this.total = this.regions.length;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Erro ao buscar a lista de Região Operacional!",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => (this.loading = false));
  }

  getRegions() {
    return new Promise((resolve, reject) => {
      try {
        const data = this.$refs.refListTable.filteredItems.map(
          (region: IRegion) => ({
            Nome: region.nome,
            Status: region.status ? "Ativo" : "Inativo",
            Autor: region.autor,
          })
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  }
}
