
import { BSkeletonImg, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";

@Component( {
  name: "AvSkeletonVerticalNavMenu",
  components: {
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper,
  }
} )
export default class AvSkeletonVerticalNavMenu extends Vue {}
