
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { BSkeletonImg, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";

@Component({
  name: "AvSkeletonInput",
  components: {
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper
  },
})
export default class AvSkeletonInput extends Vue {}
