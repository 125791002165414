
import { BCard, BSkeletonImg, BSkeleton, BSkeletonWrapper } from "bootstrap-vue";
import AvIcon from "@/components/av-icon/AvIcon.vue";

import { Component, Vue } from "vue-property-decorator";

@Component( {
  name: "AvSkeletonCard",
  components: {
    BCard,
    BSkeletonImg,
    BSkeleton,
    BSkeletonWrapper,
    AvIcon,
  }
} )
export default class AvSkeletonCard extends Vue {}
